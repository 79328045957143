import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

import * as Common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Utils from './utils'
import * as icons from './icons'
import { useSafeUpdateQuery } from './use-safe-update-query'

const duration = 800
const defaultStyle = {
  transition: `${duration}ms  cubic-bezier(0.16, 1, 0.2, 1)`,
  transform: 'translateX(-40%)',
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1, transform: 'translateX(0%)' },
  exiting: { opacity: 0, transform: 'translateX(0%)' },
  exited: { opacity: 0 },
}

const defaultStyleOverlay = {
  transition: `${duration}ms  cubic-bezier(0.16, 1, 0.2, 1)`,
  opacity: 0,
}

const transitionStylesOverlay = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
}

const Overlay = styled.div`
  position: fixed;
  z-index: 109;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: 0.5s;
`

const Wrapper = styled.div`
  ${css({
    width: ['100%', '375px'],
    backgroundColor: 'nav',
    pt: '0',
  })};
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 110;
  flex-shrink: 0;
  line-height: 1.25;
`

const BackWrapper = styled.div`
  ${css({
    fontSize: 6,
  })};

  cursor: pointer;
`

const ScrollableWrapper = styled.div`
  ${css({
    px: '8px',
  })};
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: rgb(255, 255, 255, 0.3);
      }
    }
  }
`

export function QueryDrawer(props) {
  const location = ReactRouter.useLocation()
  const nodeRef = React.useRef(null)
  const closeQuery = useSafeUpdateQuery(
    Utils.Objects.mergeDeepRight(
      { [props.activeQueryName]: null },
      props.additionalQueries
    )
  )
  const history = ReactRouter.useHistory()
  const search = location.search?.substr(1)
  const searchParams = new URLSearchParams(search)
  const active = searchParams.get([props.activeQueryName])

  function handleCloseQuery() {
    if (props.dismissable) {
      history.push(`?${closeQuery}`)
    }
  }

  return (
    <Transition
      nodeRef={nodeRef}
      unmountOnExit
      in={Boolean(active)}
      timeout={{
        exit: 300,
      }}
    >
      {state => (
        <>
          <Overlay
            ref={nodeRef}
            style={{
              ...defaultStyleOverlay,
              ...transitionStylesOverlay[state],
            }}
            onClick={handleCloseQuery}
          />
          <Wrapper
            ref={nodeRef}
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
            key={props.activeQueryName}
            data-testid={`query-drawer.container`}
          >
            <>
              <Common.Box
                p="8px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexShrink="0"
              >
                {props.showLogo && (
                  <Common.Box
                    as="img"
                    alt="Spinlra"
                    src="/images/spinlira-logo-small.svg"
                    width="117px"
                    height="27px"
                    mt="10px"
                  ></Common.Box>
                )}
                {props.onSecondaryAction ? (
                  <BackWrapper
                    data-testid="query-drawer.back-button"
                    onClick={props.onSecondaryAction}
                  >
                    {props.secondaryActionIcon ? (
                      <props.secondaryActionIcon
                        data-testid={`query-drawer.${props.title}-secondaryIcon`}
                      />
                    ) : (
                      <icons.KeyboardArrowLeft />
                    )}
                  </BackWrapper>
                ) : (
                  <Common.Box width="30px" />
                )}
                <Common.Box
                  fontSize={6}
                  fontWeight="600"
                  lineHeight="32px"
                  display="inline-flex"
                  overflow="hidden"
                  fontFamily="head"
                  data-testid="query-drawer.title"
                >
                  {props.title}
                </Common.Box>
                {props.dismissable && (
                  <Common.Text
                    onClick={handleCloseQuery}
                    data-testid="query-drawer.close-button"
                    fontSize="24px"
                    cursor="pointer"
                  >
                    <icons.Clear />
                  </Common.Text>
                )}
              </Common.Box>
              <ScrollableWrapper>{props.children}</ScrollableWrapper>
            </>
          </Wrapper>
        </>
      )}
    </Transition>
  )
}

QueryDrawer.defaultProps = {
  additionalQueries: {},
  dismissable: true,
}

QueryDrawer.propTypes = {
  activeQueryName: PropTypes.string.isRequired,
  additionalQueries: PropTypes.object,
  children: PropTypes.node,
  dismissable: PropTypes.bool,
  secondaryActionIcon: PropTypes.func,
  showLogo: PropTypes.bool,
  title: PropTypes.string,
  onSecondaryAction: PropTypes.func,
}
